import logo from './logo.svg';
import './App.css';
import 'bootstrap';

const ListCard = ({header_text, text, children, className}) => {
  const fullClassName = (className != null) ? className+' list-card' : 'list-card';
  return (
    <div className={fullClassName}>
      <h2>{header_text}</h2>
      <p>{text}</p>
      <ul>
      {children} 
      </ul>
    </div>
  )
};

const IconListItem = ({children}) => {
  return (
    <li>
      {children}
    </li>
  )
}



const App = () => {
  return (
    <div className="App">
      <div className='container'>
        <div className='row'>
          <div className="mainPanel col">
            <header className='App-header'>
              <h1>Andrew Sheldrick</h1>
            </header>

            <ListCard header_text="Welcome!" text="My name is Andrew Sheldrick, I'm known for being:">
              <li>Something!</li>
              <li>Something else!</li>
              <li>Something more different!</li>
              <li>Something else entirely!</li>
            </ListCard>

            <ListCard header_text="Recent blog posts:" className="blog-posts">
              <li>
                <a href="#">Haha you know I don't have a blog</a>
                <p>And these are definitely my blog posts</p>
              </li>
              <li>
                <a href="#">Just me bloggin away</a>
                <p>2019 Apr 11 - When I definitely made a blog post</p>
              </li>
            </ListCard>

            <ListCard header_text="Miscellaneous" className="miscellaneous">
              <li>Some kind of thing</li>
              <li>Some kind of thing</li>
              <li>Some kind of thing</li>
              <li>Some kind of thing</li>
              <li>Some kind of thing</li>
            </ListCard>
          </div>

          <div className='col sidepanel'>
            <ListCard header_text="Links" className="links-list">
              <li>
                <h3>Content</h3>
                <ul>
                  <li><a href="#">My YouTube channel</a></li>
                  <li><a href="#">My podcast</a></li>
                </ul>
              </li>
              <li>
                <h3>Personal</h3>
                <ul>
                  <li><a href="#">My Twitter</a></li>
                  <li><a href="#">My Mastodon</a></li>
                </ul>
              </li>
              <li>
                <h3>Donate/Support</h3>
                <ul>
                  <li><a href="#">Patreon</a></li>
                  <li><a href="#">Liberapay</a></li>
                </ul>
              </li>
              <li>
                <h3>Downloadable Files</h3>
                <ul>
                  <li><a href="#">My dotfiles</a></li>
                  <li><a href="#">My scripts</a></li>
                </ul>
              </li>
            </ListCard>
          </div>
        </div>
      </div>
        <footer>
          <center>
            <a href="#">RSS</a>
            <a href="#">Updates</a>
            <a href="#">Donate</a>
          </center>
        </footer>
    </div>
  );
}

export default App;